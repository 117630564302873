<template>
  <ion-page>
    <HeaderModal :title="title" @close-modal="handleCloseModal" />
    <ion-content>
      <ion-list class="ion-no-padding" mode="md">
        <ion-item v-for="(item, index) in data" :key="index" @click="handleUpdate(index)">
          <ion-label class="font-style-toolbar-button-regular text-gray-900">{{ item.company }}</ion-label>
          <ion-checkbox slot="end" ref="input" :checked="arrayKey.includes(index) ? true : false">
          </ion-checkbox>
        </ion-item>
      </ion-list>
      <ion-infinite-scroll
        @ionInfinite="loadData($event)"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button mode="md" expand="block" class="font-style-text" @click="handleSelectCompany">{{
          $t('apply')
        }}</ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import HeaderModal from '@/components/header-modal/index.vue';
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  components: {
    HeaderModal
  },
  emits: ['close-modal'],
  props: ['company', 'cacheCompanySelect'],
  setup(props, ctx) {
    //variables
    const { t } = useI18n();
    const sliceCompany = ref(props.cacheCompanySelect ? props.cacheCompanySelect.length : 15);
    const data = ref(
      props.cacheCompanySelect ? props.cacheCompanySelect : props.company.slice(0, sliceCompany.value)
    );
    const title = ref(t('selectCompany.select_company'));
    const isDisabled = ref(false);
    const input = ref(null);
    const arrayKey = ref([]);
    //function
    const handleCloseModal = () => {
      ctx.emit('set-close-modal');
    };
    const handleSelectCompany = () => {
      for (const i in data.value) {
        arrayKey.value.includes(+i) ? (data.value[i].isChecked = true) : (data.value[i].isChecked = false);
      }
      const filterChecker = data.value.filter((item) => item.isChecked === true);
      if (filterChecker.length > 0) {
        ctx.emit('send-company-select', data.value, filterChecker);
      } else {
        return;
      }
    };

    const handleUpdate = (index) => {
      if (index === 0 && !arrayKey.value.includes(index)) {
        arrayKey.value = [];
        arrayKey.value.push(index);
      } else {
        if (!arrayKey.value.includes(index)) {
          arrayKey.value.push(index);
          const findKeyAll = arrayKey.value.findIndex((item) => item === 0);
          delete arrayKey.value[findKeyAll];
        } else {
          const findKey = arrayKey.value.findIndex((item) => item === index);
          delete arrayKey.value[findKey];
        }
      }
    };
    const loadData = (ev) => {
      if (props.company.length === data.value.length) {
        isDisabled.value = true;
        ev.target.disabled = true;
      } else {
        data.value = data.value.concat(props.company.slice(sliceCompany.value, sliceCompany.value + 15));
        sliceCompany.value = sliceCompany.value + 15;
        ev.target.complete();
      }
    };
    onMounted(() => {
      for (const i in data.value) {
        if (data.value[i].isChecked === true) {
          arrayKey.value.push(+i);
        }
      }
    });

    return {
      input,
      data,
      arrayKey,
      handleCloseModal,
      title,
      handleSelectCompany,
      loadData,
      handleUpdate
    };
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
