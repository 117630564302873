<template>
  <ion-grid class="ion-padding">
    <ion-row>
      <ion-col size="3">
        <td-image :image="sku?.image" :imagePath="IMAGE_PATH.PRODUCT"></td-image>
      </ion-col>
      <ion-col size="9" class="d-flex flex-column">
        <div class="d-flex flex-column">
          <ion-text class="font-style-button-small text-gray-900 mb-1">{{ sku.name }}</ion-text>
          <ion-text :class="['font-style-tab-bar text-white-000 mb-1 px-1', computedRef.halal[0]]">{{
            computedRef.halal[1]
          }}</ion-text>
          <ion-text class="font-style-tab-bar text-gray-700 mb-1">{{ sku.tenant_name }}</ion-text>
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <div class="d-flex flex-column">
          <ion-text class="font-style-card-subtitle text-secondaryy text-uppercase">{{
            $t('packaging_size')
          }}</ion-text>
          <ion-text class="font-style-title text-black-primary mt-1">{{
            computedRef.packagingSize
          }}</ion-text>
        </div>
      </ion-col>

      <ion-col>
        <div class="d-flex flex-column">
          <ion-text class="font-style-card-subtitle text-secondaryy text-uppercase">{{
            $t('weight')
          }}</ion-text>
          <ion-text class="font-style-title text-black-primary mt-1">{{ computedRef.weight }} </ion-text>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="mt-2">
      <ion-col class="d-flex flex-column">
        <ion-text class="text-gray-700">{{
          userData.user_type_id === 6 || userData.user_type_id === 12
            ? $t('regular_price')
            : $t('direct_price')
        }}</ion-text>
        <ion-text class="direct-price my-1">{{ computedRef.price }}</ion-text>
        <ion-text class="price-unit">{{ computedRef.price_per_weight }}</ion-text>
      </ion-col>
      <ion-col v-if="userData.user_type_id === 2 || userData.user_type_id === 7" class="d-flex flex-column">
        <ion-text class="text-gray-700">{{ $t('quotation_limit_price') }}</ion-text>
        <ion-text class="direct-price my-1">{{ computedRef.price_quote }}</ion-text>
        <ion-text class="price-unit">{{ computedRef.price_per_weight_quote }}</ion-text>
        <!-- <ion-row v-if="userData.user_type_id === 6 || userData.user_type_id === 12">
          <ion-col size="1">
            <ion-icon class="info-icon" :icon="informationCircleOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-tex class="price-info">{{ $t('warning_info_price') }}</ion-tex></ion-col
          >
        </ion-row> -->
        <!-- <div v-else class="d-flex flex-column">
          <ion-text class="direct-price my-1">-</ion-text>
          <ion-text class="price-unit">-</ion-text>
        </div> -->
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button
          @click="toProductDetail(sku.product_id)"
          expand="block"
          color="primary"
          fill="outline"
          class="text-capitalize"
          >{{ $t('view_product') }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import { displayPrice } from '@/modules/b2b/services/libs/helper';
import { IMAGE_PATH } from '@/modules/sale/constants';
import { getUserType, handleCheckHalal, handlePricePerWeight, useCheck } from '@/modules/shared/utils/';
import { informationCircleOutline } from 'ionicons/icons';
import { computed, reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
export default {
  name: 'ProductDetails',
  emits: ['closeModal'],
  props: {
    sku: {
      type: Object,
      default: () => ({})
    },
    user: {
      type: Object,
      default: () => ({})
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const userData = ref(props.user);
    const router = useRouter();
    const prefixPath = () => getUserType(userData.value.user_type_id);
    const skuVariation = ref(
      JSON.parse(props.sku.specs, (_, value) => {
        return value;
      })
    );
    const { checkWeightItem, combineTwoUnit, isItemKGAndNoneUnit } = useCheck();
    const computedRef = reactive({
      halal: computed(() => {
        return handleCheckHalal(props.sku.halal);
      }),
      packagingSize: computed(() => {
        if (props.sku) {
          const { unit_amount, uom_name, unit_per_item, unit_per_item_uom } = props.sku;
          if (isItemKGAndNoneUnit(uom_name, unit_per_item)) {
            return '';
          }
          const itemsInProduct = checkWeightItem(unit_amount, uom_name);
          const unitsInItem = checkWeightItem(unit_per_item, unit_per_item_uom);
          return combineTwoUnit(itemsInProduct, unitsInItem);
        }
        return '';
      }),
      weight: computed(() => {
        return props.sku.weight + ' ' + 'kg';
      }),
      price: computed(() => {
        return (
          props.currencySymbol +
          displayPrice(
            props.user.user_type_id === 6 || props.user.user_type_id === 12
              ? props.sku.current_price
              : props.sku.direct_price
          )
        );
      }),
      price_per_weight: computed(() => {
        return handlePricePerWeight(
          props.currencySymbol,
          props.user.user_type_id === 6 || props.user.user_type_id === 12
            ? props.sku.current_price
            : props.sku.direct_price,
          props.sku.weight,
          'KG'
        );
      }),
      price_quote: computed(() => {
        return props.user.user_type_id === 2 || props.user.user_type_id === 7
          ? props.currencySymbol + displayPrice(props.sku.quotation_limit_price)
          : '';
      }),
      price_per_weight_quote: computed(() => {
        return handlePricePerWeight(
          props.currencySymbol,
          props.sku.quotation_limit_price,
          props.sku.weight,
          'KG'
        );
      })
    });
    const toProductDetail = (productId) => {
      router.push({
        path: `/${prefixPath()}/main/product-detail/${productId}`
      });
      emit('closeModal');
    };
    return {
      computedRef,
      toProductDetail,
      skuVariation,
      userData,
      informationCircleOutline,
      IMAGE_PATH
    };
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
