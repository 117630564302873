<template>
  <ion-row>
    <ion-col size="12" @click="toggleCollapse">
      <div class="d-flex align-center">
        <ion-col size="1">
          <ion-icon class="info-icon" :icon="informationOutline" />
        </ion-col>
        <ion-col size="10" class="d-flex align-center">
          <div class="d-flex align-center promotion">
            <ion-text class="quote-info">{{ $t('following_company') }}</ion-text>
          </div>
        </ion-col>
        <ion-col size="1" class="">
          <div class="d-flex">
            <div :class="['toggle-icon', isExpanded ? 'rotate-180' : '']">
              <ion-icon :icon="chevronDownOutline" />
            </div>
          </div>
        </ion-col>
      </div>
    </ion-col>
    <div v-if="isExpanded">
      <transition-expand v-for="(id, index) in item" :key="index">
        <ion-col size="12" class="d-flex flex-column">
          <ion-text class="font-style-chip text-gray-700">{{ item[index].name }}</ion-text>
        </ion-col>
      </transition-expand>
      <ion-button
        :disabled="item.length >= totalCustomer"
        class="expand-btn"
        @click="$emit('loadMore', item.length, 2)"
        expand="block"
        fill="clear"
        >Load more
      </ion-button>
    </div>
  </ion-row>
</template>

<script>
import { chevronDownOutline, informationOutline } from 'ionicons/icons';
import { ref, defineAsyncComponent } from 'vue';
import { useI18n } from 'vue-i18n';
const TransitionExpand = defineAsyncComponent(async () => {
  return import('@/modules/b2b/views/shared/TransitionExpand.vue');
});
export default {
  props: ['item', 'totalCustomer'],
  components: {
    TransitionExpand
  },
  setup() {
    //variables
    const { t } = useI18n();
    const isExpanded = ref(false);
    //functions
    const toggleCollapse = () => (isExpanded.value = !isExpanded.value);
    return {
      isExpanded,
      toggleCollapse,
      informationOutline,
      chevronDownOutline,
      t
    };
  }
};
</script>
<style src="./style.scss" lang="scss" scoped></style>
