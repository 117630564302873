<template>
  <ion-page>
    <Header
      :title="$t('product_price_update')"
      :userType="user?.user_type_id"
      @close-modal="$emit('close-modal')"
    />
    <ion-content v-if="isShow">
      <ProductDetails
        :sku="items.sku"
        :user="user"
        :currencySymbol="computedRef.currencySymbol"
        @close-modal="$emit('close-modal')"
      />
      <!-- Hide for now (request from Designer) -->
      <!-- <div class="px-3 pb-3">
        <ion-button @click="true" class="view-product-button" expand="block" fill="outline">{{
          $t('view_product')
        }}</ion-button>
      </div> -->
      <div class="padding"></div>
      <ion-grid class="ion-padding">
        <ion-row>
          <ion-col size="6">
            <ion-text class="font-style-card-subtitle text-black-700 text-uppercase">{{
              $t('price_change_log')
            }}</ion-text>
          </ion-col>
          <ion-col size="6">
            <ion-text class="d-flex justify-end font-style-card-subtitle fw-400 text-gray-700">{{
              dayjs(createdAt).format('DD MMM YYYY, hh:mm A')
            }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="mt-2" v-if="user.user_type_id === 6 || user.user_type_id === 12">
          <ion-col size="1">
            <ion-icon class="info-icon" :icon="informationCircleOutline"></ion-icon>
          </ion-col>
          <ion-col>
            <ion-text class="price-info">{{ $t('price_log_info') }}</ion-text></ion-col
          >
        </ion-row>
        <!-- will use this in revamp design -->
        <!-- <ion-row v-if="!computedRef.isHiddenSelection">
          <ion-col size="12"
            ><ion-text class="font-style-title text-black-primary">{{
              $t('selectCompany.select_company')
            }}</ion-text></ion-col
          >
        </ion-row> -->
        <!-- <ion-row>
          <ion-col v-if="!computedRef.isHiddenSelection" size="12" class="ion-no-padding">
            <ion-button fill="outline" color="grey2" @click="handleOpenModalSelectCompany(true)">
              <span class="btn-open-modal font-style-content-paragraph-regular text-gray-800"
                >{{ isSelectAllCompany ? $t('all') : $t('all_is_un_selected') }}
                <ion-icon :icon="chevronDownOutline"></ion-icon>
              </span>
            </ion-button>
          </ion-col>
        </ion-row> -->
        <div
          v-if="
            (user.user_type_id === 2 || user.user_type_id === 7) &&
            (items.directPriceCustomers?.totalCount > 0 || items.directPriceCustomers?.totalCount !== null)
          "
          class="ml-1 mt-4 mb-1 direct-capital text-gray-700"
        >
          {{ $t('direct_price') }}
        </div>
        <CompanyItem
          v-if="user.user_type_id === 6 || user.user_type_id === 12"
          @loadMore="loadMore"
          :item="items.customers"
          :prev_price="items.sku.previous_price"
          :currencySymbol="computedRef.currencySymbol"
          :current_price="items.sku.current_price"
          :totalCustomer="items.totalCount"
        />
        <CompanyItem
          v-if="
            (user.user_type_id === 2 || user.user_type_id === 7) &&
            (items.directPriceCustomers?.totalCount > 0 || items.directPriceCustomers?.totalCount !== null)
          "
          @loadMore="loadMore"
          :item="items.directPriceCustomers?.data"
          :prev_price="items.sku.previous_direct_price"
          :currencySymbol="computedRef.currencySymbol"
          :current_price="items.sku.direct_price"
          :totalCustomer="items.directPriceCustomers.totalCount"
        />

        <div
          v-if="
            (user.user_type_id === 2 || user.user_type_id === 7) &&
            (items.limitPriceCustomers?.totalCount > 0 || items.limitPriceCustomers?.totalCount !== null)
          "
          class="ml-1 mt-4 mb-1 direct-capital text-gray-700"
        >
          {{ $t('quotation_limit_price') }}
        </div>
        <DirectPriceCompany
          v-if="
            (user.user_type_id === 2 || user.user_type_id === 7) &&
            (items.limitPriceCustomers?.totalCount > 0 || items.limitPriceCustomers?.totalCount !== null)
          "
          @loadMore="loadMore"
          :item="items.limitPriceCustomers?.data"
          :totalCustomer="items.limitPriceCustomers.totalCount"
        />
      </ion-grid>
    </ion-content>
    <ion-modal
      mode="md"
      @didDismiss="handleCloseModal"
      :is-open="isOpen"
      :backdrop-dismiss="true"
      css-class="modal-filter-select-company"
    >
      <ModalSelectCompany
        @set-close-modal="handleCloseModal"
        @send-company-select="handleGetCompanySelect"
        :company="company"
        :cacheCompanySelect="cacheCompanySelect"
      />
    </ion-modal>
  </ion-page>
</template>

<script>
import Header from '@/components/header/index.vue';
import { apolloClient } from '@/main';
import { IMAGE_PATH } from '@/modules/b2b/constants/index';
import { useLoading } from '@/modules/shared/utils/';
import { getPriceUpdateDetails, saleGetPriceUpdateDetails } from '@/services/shared/graphql';
import dayjs from 'dayjs';
import { chevronDownOutline, informationCircleOutline } from 'ionicons/icons';
import { computed, inject, reactive, ref } from 'vue';
import CompanyItem from './CompanyItem/index.vue';
import DirectPriceCompany from './DirectPriceCompany/index.vue';
import ModalSelectCompany from './ModalSelectCompany/index.vue';
import ProductDetails from './ProductDetails/index.vue';

export default {
  name: 'PriceUpdateDetails',
  components: {
    Header,
    ProductDetails,
    CompanyItem,
    DirectPriceCompany,
    ModalSelectCompany
  },
  props: ['skuId', 'subType', 'createdAt', 'accountNumber'],
  setup(props) {
    const { showLoading } = useLoading();
    const isOpen = ref(false);
    const isShow = ref(false);
    const setShow = (state) => (isShow.value = state);
    const items = ref([]);
    const storage = inject('$storage');
    const company = ref([
      {
        id: -1,
        company: 'All',
        isChecked: true
      }
    ]);
    const user = ref(null);
    const customer = ref(null);
    const cacheCompanySelect = ref(null);
    const isSelectAllCompany = ref(true);
    const handleGetUserInfor = async () => (user.value = await storage.getUser());
    //computed
    const computedRef = reactive({
      currencySymbol: computed(() => {
        return user.value && user.value.country.currency_symbol;
      }),
      isHiddenSelection: computed(() => {
        return items.value?.customers?.length === 0;
      })
    });

    //function
    const handleOpenModalSelectCompany = (params) => (isOpen.value = params);
    const handleCloseModal = () => (isOpen.value = false);
    const isMerchantUser = () => {
      return user.value?.user_type_id === 6 || user.value?.user_type_id === 12;
    };
    const handleGetPriceUpdateDetails = async (
      accountNumber = customer.value?.account_number,
      sku,
      subType,
      limit,
      limitPrice
    ) => {
      if (isMerchantUser()) {
        const { data } = await apolloClient.query({
          query: getPriceUpdateDetails,
          variables: {
            skuId: sku,
            subType: subType,
            accountNumber: accountNumber,
            limit: 10 + limit,
            offset: 0
          }
        });
        return data.getPriceUpdateDetails;
      } else {
        const { data } = await apolloClient.query({
          query: saleGetPriceUpdateDetails,
          variables: {
            skuId: sku,
            subType: subType,
            limitForDirectPrice: 10 + limit,
            offsetForDirectPrice: 0,
            limitForLimitPrice: 10 + limitPrice,
            offsetForLimitPrice: 0
          }
        });
        return data.saleGetPriceUpdateDetails;
      }
    };

    const loadMore = async (n, id) => {
      if (id === 1) {
        items.value = await handleGetPriceUpdateDetails(
          props.accountNumber,
          props.skuId,
          props.subType,
          n,
          0
        );
      } else {
        items.value = await handleGetPriceUpdateDetails(
          props.accountNumber,
          props.skuId,
          props.subType,
          0,
          n
        );
      }
    };

    const handleGetCompany = async () => {
      for (const i in user.value?.user_type_id === 6 || user.value?.user_type_id === 12
        ? items.value?.customers
        : items.value?.directPriceCustomers?.data) {
        for (const params of user.value?.user_type_id === 6 || user.value?.user_type_id === 12
          ? items.value?.customers[i]
          : items.value?.directPriceCustomers?.data[i]) {
          company.value.push({
            id: params.customer.id,
            company: params.customer.name,
            isChecked: false
          });
        }
      }
    };
    const handleGetData = async () => {
      showLoading(() => {
        Promise.allSettled([
          handleGetUserInfor(),
          handleGetPriceUpdateDetails(props.accountNumber, props.skuId, props.subType, 0, 0)
        ])
          .then(async (data) => {
            items.value = data[1].value;
            await handleGetCompany();
          })
          .catch(async (error) => {
            console.log(error);
          })
          .finally(async () => {
            setShow(true);
          });
      });
    };
    const handleGetCompanySelect = async (listCustomerSelect, filterChecker) => {
      handleCloseModal();
      cacheCompanySelect.value = listCustomerSelect;
      const isSelectAll = filterChecker.find((item) => item.id === -1);
      if (isSelectAll) {
        items.value = await handleGetPriceUpdateDetails(
          props.accountNumber,
          props.skuId,
          props.subType,
          0,
          0
        );
        isSelectAllCompany.value = true;
      } else {
        let data = [];
        for (const value of filterChecker) {
          if (!data.includes(value.id)) {
            data.push(value.id);
          }
        }
        items.value = await handleGetPriceUpdateDetails(props.accountNumber, props.skuId, props.subType, 0);
        isSelectAllCompany.value = false;
      }
    };
    return {
      isOpen,
      items,
      user,
      customer,
      company,
      storage,
      isShow,
      computedRef,
      isSelectAllCompany,
      cacheCompanySelect,
      handleOpenModalSelectCompany,
      handleCloseModal,
      handleGetCompanySelect,
      handleGetUserInfor,
      loadMore,
      chevronDownOutline,
      informationCircleOutline,
      dayjs,
      IMAGE_PATH,
      handleGetData
    };
  },

  async mounted() {
    this.user = await this.storage.getUser();
    this.customer = await this.storage.getSelectedCompany();
    await this.handleGetData();
  }
};
</script>

<style src="./style.scss" lang="scss" scoped></style>
